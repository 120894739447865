import React  from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

export interface MarketplaceSelectorProps {
  selectedOption: string;
  formValueSetter: (inputText: string) => void;
}

export interface MarketplaceMapping {
  id: number;
  obfuscatedId: string;
  countryName: string;
}

// https://w.amazon.com/index.php/MarketplaceID
// (2/23/22) list of marketplaceIds pulled from G2S2 configs
//  [1, 3, 4, 5, 6, 7, 3240, 13152, 35691, 44551, 44571, 111172, 121322,
//  252870, 264730, 265380, 265620, 265640, 265660, 265680, 279841, 328451,
//  330551, 330711, 330731, 330871, 330921, 338801, 338811, 526970, 771770,
//  1071810, 1071830, 1221570, 1259700, 1268170, 1338980, 1344430, 1344820,
//  1344830, 1344840, 1344860, 623225021, 704403121, 712115121]
// NOTE: Skipped Prime NOW marketplaces
const MARKETPLACE_OPTIONS: MarketplaceMapping[] = [
  { id: 1, obfuscatedId: 'ATVPDKIKX0DER', countryName: 'United States' },
  { id: 3, obfuscatedId: 'A1F83G8C2ARO7P', countryName: 'United Kingdom' },
  { id: 4, obfuscatedId: 'A1PA6795UKMFR9', countryName: 'Germany' },
  { id: 5, obfuscatedId: 'A13V1IB3VIYZZH', countryName: 'France' },
  { id: 6, obfuscatedId: 'A1VC38T7YXB528', countryName: 'Japan' },
  { id: 7, obfuscatedId: 'A2EUQ1WTGCTBG2', countryName: 'Canada' },
  { id: 3240, obfuscatedId: 'AAHKV2X7AFYLW', countryName: 'China' },
  { id: 35691, obfuscatedId: 'APJ6JRA9NG5V4', countryName: 'Italy' },
  { id: 44551, obfuscatedId: 'A1RKKUPIHCS9HS', countryName: 'Spain' },
  { id: 44571, obfuscatedId: 'A21TJRUUN4KGV', countryName: 'India' },
  { id: 111172, obfuscatedId: 'A39IBJ37TRP1C6', countryName: 'Australia' },
  { id: 328451, obfuscatedId: 'A1805IZSGTT6HS', countryName: 'Netherlands' },
  { id: 338811, obfuscatedId: 'A17E79C6D8DWNP', countryName: 'Saudi Arabia' },
  { id: 526970, obfuscatedId: 'A2Q3Y263D00KWC', countryName: 'Brazil' },
  { id: 771770, obfuscatedId: 'A1AM78C64UM0Y8', countryName: 'Mexico' },
  { id: 623225021, obfuscatedId: 'ARBP9OOSHTCHU', countryName: 'Egypt' },
  { id: 679831071, obfuscatedId: 'AMEN7PMS3EDWL', countryName: 'Belgium' },
  { id: 704403121, obfuscatedId: 'A2NODRKZP88ZB9', countryName: 'Sweden' },
  { id: 712115121, obfuscatedId: 'A1C3SOZRARQ6R3', countryName: 'Poland' }
].sort((a, b) => (a.countryName > b.countryName ? 1 : b.countryName > a.countryName ? -1 : 0));

export default function MarketplaceSelector({ selectedOption, formValueSetter }: MarketplaceSelectorProps) {

  const obfuscatedMarketplaceIdSet = new Set(MARKETPLACE_OPTIONS.map((option) => option.obfuscatedId));

  return (
    <Typeahead
      defaultSelected={MARKETPLACE_OPTIONS.filter((option) => option.obfuscatedId === selectedOption)}
      options={MARKETPLACE_OPTIONS}
      labelKey={'obfuscatedId'}
      id={'marketplaceSelector'}
      filterBy={['countryName']} // Allow input search by countryName
      onInputChange={(text: string) => {
        formValueSetter(text.toUpperCase());
      }}
      onChange={(selected: any[]) => {
        if (selected.length > 0 && obfuscatedMarketplaceIdSet.has(selected[0].obfuscatedId)) {
          formValueSetter(selected[0].obfuscatedId);
        }
      }}
      renderMenuItemChildren={(option: any) => {
        return (
          <span id={option.id}>
            {option.obfuscatedId} : {option.countryName}
          </span>
        );
      }}
      highlightOnlyResult={true}
      emptyLabel={'No marketplace hints available.'}
    />
  );
}
